import { takeEvery, call, put,takeLatest, delay } from "redux-saga/effects";
import { 
  FORMULARY_SEARCH,
  FORMULARY_FORMULARY_BRANDDETAIL,
  FORMULARY_ADD,
  FORMULARY_TEMP_SEARCH,
  FORMULARY_TEMP_SEARCH_ADD 
} from "../actions";
import doctorService from "../services/doctorService";
import * as actions from "../actions";
import { errorResponsesArray } from '../utils/helper'
import { slackDebugger } from '../utils/slackDebugger'

export function* watcherFormularySaga() {
  yield takeEvery(FORMULARY_SEARCH, workerFormularySaga);
  yield takeLatest(FORMULARY_FORMULARY_BRANDDETAIL, workerFormularyBrandSaga);
  yield takeLatest(FORMULARY_ADD, workerFormularyAddSaga);
  yield takeLatest(FORMULARY_TEMP_SEARCH, workerTempSearchSaga);
  yield takeLatest(FORMULARY_TEMP_SEARCH_ADD, workerTempSearchAddSaga);


}

function* workerFormularySaga(action) {
  yield put(actions.formularySearchStart());
  try {
    const res = yield call(doctorService.searchFormulary,action.act,action.term)
    // console.log(res.data.data.length)
    yield put(actions.formularySearchSuccess(res.data.data));
  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_drug_data?api_type=solr',
       method:'GET',
     })
    }
    yield put(actions.formularySearchFail(error));
  }
}

function* workerFormularyBrandSaga(action) {
  yield put(actions.formularyBrandDetailStart());
  try {
    const res = yield call(doctorService.searchFormulary,action.drug)
    yield put(actions.formularyBrandDetailSuccess(res.data.data));

  } catch (error) {
    yield put(actions.formularyBrandDetailFail(error));
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'get_drug_data?api_type=solr',
       method:'GET',
     })
    }
  }
}

function* workerFormularyAddSaga(action) {
  yield put(actions.formularyAddStart());
  try {
    let drug_formulary ={}
    drug_formulary.drug_name= action.drug_formulary.drug_name
    drug_formulary.strength= action.drug_formulary.strength
    drug_formulary.strength_unit= action.drug_formulary.strength_unit
    drug_formulary.generic_name= action.drug_formulary.generic_name
    drug_formulary.drug_type= action.drug_formulary.drug_type
    drug_formulary.manufacturer= action.drug_formulary.manufacturer
    
    const res = yield call(doctorService.addFormulary,drug_formulary)
    let drug =[]
    drug.push({
      id:res.data.data.id,
      drug_name: res.data.data.drug_name,
      is_temp:1,
      // cims_guid:res.data.data.cims_guid

    })
    if(res.status === 201){
    yield put(actions.formularyAddSuccess(res.data.message));
    yield put(actions.searchDrugInFormularySuccess(drug));
    }else{
      alert('Something went wrong !!')
    }

  } catch (error) {
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'drug_formularies',
       method:'POST',
     })
    }
    yield put(actions.formularyAddFail(error));
    alert(error.response.data.error);
  }
}

function* workerTempSearchSaga(action) {
  yield put(actions.formularyTempSearchStart());
  try {
    // yield delay(3000)
    if(action.tempFormulary !=='therapeutic_classes=undefined'){
    const res = yield call(doctorService.searchPendingTempDrug,action.tempFormulary, action.visit_id)  
    yield put(actions.formularyTempSearchSuccess(res.data.data));
    }

  } catch (error) {
    yield put(actions.formularyTempSearchFail(error));
    if(errorResponsesArray.includes(parseInt(error.response.status))){
      slackDebugger({
       actionType:"errorLogging",
       errorMessage:error.response.statusText,
       errorCode : error.response.status,
       endPoint:'search_pending_temp_drugs?drug_name',
       method:'GET',
     })
    }
  }
}

function* workerTempSearchAddSaga(action) {
  yield put(actions.formularyTempAddStart());
  try {
    let drug =[{
      id:action.drug_formulary.id,
      drug_name: action.drug_formulary.drug_name,
      is_temp:1

    }]
    yield put(actions.formularyTempAddSuccess());
    yield put(actions.searchDrugInFormularySuccess(drug));

  } catch (error) {
    
    yield put(actions.formularyTempAddFail(error));
  }
}




